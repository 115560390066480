import store from "app/store";
import { history } from "Routes";

const logout = () => {
  // clear tokens from localStorage
  localStorage.removeItem("access_token");
  localStorage.removeItem("language");
  store.dispatch({ type: "reset-store" });
  // logout by navigate to login screen
  const loginRoute = "/login";
  history.replace(loginRoute);
};

export default logout;
