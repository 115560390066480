import { Suspense, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import portals from "constants/portals";
import { isNil } from "ramda";
import { refreshToken } from "store/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import Layout from "components/Layout/Layout";

export const checkCSAdminRole = (access_token) => {
  const decoded = jwt_decode(access_token);
  const { role } = decoded;
  const roles = JSON.parse(role);
  return portals.some((portal) => portal.roleChecker(roles) === true);
};

const useLoginStatus = () => {
  let isLoggedIn;
  const access_token = localStorage.getItem("access_token");
  if (!isNil(access_token)) {
    isLoggedIn = checkCSAdminRole(access_token);
  } else {
    isLoggedIn = false;
  }

  return isLoggedIn;
};

const RouteRequiresLogin = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useLoginStatus();
  const { userID } = useSelector((state) => state.login);

  useEffect(() => {
    if (userID === null) {
      dispatch(refreshToken());
    }
  }, [dispatch, userID]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Layout>
            <Suspense fallback={<div />}>
              <Component {...props} />
            </Suspense>
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default RouteRequiresLogin;
