import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AppShell,
  Avatar,
  Burger,
  Button,
  Center,
  Header,
  MediaQuery,
  MenuItem,
  Navbar,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Menu } from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import logout from "helpers/logout";
import data from "constants/data";
import { useDispatch, useSelector } from "react-redux";
import { selectMenu } from "store/config/configSlice";
import checkFinanceRole from "helpers/checkFinanceRole";

let csMenus = ({ id }) => [
  {
    id: 1,
    name: "search",
    url: "/search",
    selection: [`/paymentDetail/${id}`, "/searchResult", "/search"],
  },
];

let financeMenus = ({ id }) => [
  {
    id: 1,
    name: "search",
    url: "/search",
    selection: [`/paymentDetail/${id}`, "/searchResult", "/search"],
  },
  {
    id: 2,
    name: "summary",
    url: "/summary",
    selection: [`/summary/paymentDetail/${id}`, "/summary"],
  },
  // {
  //   id: 3,
  //   name: "upload",
  //   url: "/upload",
  //   selection: ["/upload"],
  // },
];

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [avatarOpened, setAvatarOpened] = useState(false);
  const theme = useMantineTheme();
  const { selectedMenu } = useSelector((state) => state.config);
  const notifications = useNotifications();
  const access_token = localStorage.getItem("access_token");

  let { id } = useParams();

  useEffect(() => {
    dispatch(selectMenu(history.location.pathname));
  }, [dispatch, history.location.pathname]);

  // Event Handlers
  const logoutBtnClickHandler = () => {
    logout();
    notifications.showNotification({
      color: "green",
      title: "Log out successful!",
      message: "You have successfully logged out",
    });
  };

  const menus = ({ id }) =>
    !checkFinanceRole(access_token) ? csMenus({ id }) : financeMenus({ id });

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          padding="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 250, lg: 250 }}
          sx={{ padding: 0 }}
        >
          <Navbar.Section>
            {menus({ id }).map(({ id, name, url, selection }) => (
              <Center key={id}>
                <Button
                  variant={
                    selection.includes(selectedMenu) ? "light" : "subtle"
                  }
                  fullWidth
                  size="md"
                  radius={0}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: `${
                      selection.includes(selectedMenu) ? "#248ae6" : "#000"
                    }`,
                  }}
                  onClick={() => {
                    dispatch(selectMenu(url));
                    history.push(url);
                  }}
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </Button>
              </Center>
            ))}
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={70} padding="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Text>{data.title}</Text>
            <Menu
              style={{ cursor: "pointer" }}
              position="bottom"
              placement="end"
              control={
                <Avatar
                  defaultValue={avatarOpened}
                  onChange={() => setAvatarOpened(!avatarOpened)}
                  radius="xl"
                  src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                />
              }
            >
              <MenuItem onClick={logoutBtnClickHandler}>Logout</MenuItem>
            </Menu>
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
};

export default Layout;
