import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
import { lazy } from "react";
import RouteRequiresLogin from "components/Shared/RouteRequiresLogin";
import { Redirect } from "react-router-dom";
import checkFinanceRole from "helpers/checkFinanceRole";

const SearchResult = lazy(() => import("./pages/SearchResult/SearchResult"));
const PaymentDetail = lazy(() => import("./pages/PaymentDetail/PaymentDetail"));
const Search = lazy(() => import("./pages/Search/Search"));
const Summary = lazy(() => import("./pages/Summary/Summary"));
const UploadPage = lazy(() => import("./pages/UploadPage/UploadPage"));

export const history = createBrowserHistory({
  basename: "/",
});

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <RouteRequiresLogin
          exact
          path="/paymentDetail/:id"
          component={PaymentDetail}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/summary/paymentDetail/:id"
          component={PaymentDetail}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/searchResult"
          component={SearchResult}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/search"
          component={Search}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/summary"
          component={Summary}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/upload"
          component={UploadPage}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/"
          component={() => {
            const access_token = localStorage.getItem("access_token");
            const isFinance = checkFinanceRole(access_token);
            return isFinance ? (
              <Redirect to="/summary" />
            ) : (
              <Redirect to="/search" />
            );
          }}
        ></RouteRequiresLogin>
        <Route exact path="/login">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
