import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenu: "/",
};

const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    selectMenu: (state, { payload }) => {
      state.selectedMenu = payload;
    },
  },
  extraReducers: {},
});

export const { selectMenu } = configSlice.actions;

const { reducer } = configSlice;
export default reducer;
