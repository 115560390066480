import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import loginReducer from "store/login/loginSlice";
import paymentReducer from "store/payment/paymentSlice";
import uploadReducer from "store/upload/uploadSlice";
import configReducer from "store/config/configSlice";
import { createLogger } from "redux-logger";

const middleware = [...getDefaultMiddleware()];

if (process.env.NODE_ENV !== "production") {
  const logger = createLogger();
  // add logger middleware
  middleware.push(logger);
}

const combinedReducer = combineReducers({
  login: loginReducer,
  payment: paymentReducer,
  upload: uploadReducer,
  config: configReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "reset-store") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
