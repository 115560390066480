// import handleResponse from "helpers/handleAPIResponse";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const postUploadCSV = (data) => {
  console.log("data", data);
  return fetch(`${baseUrl}/subscriptiontransaction`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      // "content-type": "multipart/form-data",
    },
    body: data,
  })
    .then((response) => {
      return response.status;
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Post upload failed");
    });
};
