import {
  checkCSAdminCustomerSupport,
  checkCSAdminFinance,
} from "helpers/roleChecker";

const portals = [
  {
    id: 1,
    name: "CSAdminFinance",
    roleChecker: checkCSAdminFinance,
  },
  {
    id: 2,
    name: "CSAdminCustomerSupport",
    roleChecker: checkCSAdminCustomerSupport,
  },
];

export default portals;
