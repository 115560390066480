import jwt_decode from "jwt-decode";
import { checkCSAdminFinance } from "./roleChecker";

const checkFinanceRole = (access_token) => {
  const decoded = jwt_decode(access_token);
  const { role } = decoded;
  const roles = JSON.parse(role);
  return checkCSAdminFinance(roles);
};

export default checkFinanceRole;
