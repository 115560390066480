import React from "react";
import ReactDOM from "react-dom";
import store from "app/store";
import { Provider } from "react-redux";
import { NotificationsProvider } from "@mantine/notifications";
// import { MantineProvider } from "@mantine/core";
// import { ModalsProvider } from "@mantine/modals";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationsProvider position="top-right">
        <App />
      </NotificationsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
