import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "services/login";
import { postUploadCSV } from "services/uploads";

export const uploadCSV = createAsyncThunk(
  "uploads/uploadCSV",
  async (params) => {
    try {
      const res = await fetchWrapper(postUploadCSV, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Upload subscription failed");
    }
  }
);

const initialState = {
  records: [],
  isSubmitting: false,
  isErrors: [],
};

const paymentSlice = createSlice({
  name: "uploadSlice",
  initialState,
  reducers: {
    resetUpload: (state) => {
      state.records = [];
      state.isSubmitting = false;
      state.isErrors = [];
    },
    setErrors: (state, { payload }) => {
      state.isErrors = payload;
    },
  },
  extraReducers: {
    [uploadCSV.pending]: (state) => {
      state.isErrors = [];
      state.isSubmitting = true;
    },
    [uploadCSV.fulfilled]: (state, { payload }) => {
      state.isErrors = [];
      state.isSubmitting = false;
      state.records = payload;
    },
    [uploadCSV.rejected]: (state, action) => {
      state.isSubmitting = false;
      state.isErrors = action.error.message || [];
    },
  },
});

export const { resetUpload, setErrors } = paymentSlice.actions;

const { reducer } = paymentSlice;
export default reducer;
