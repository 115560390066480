import handleResponse from "helpers/handleAPIResponse";
import { isNil } from "ramda";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchPaymentDetails = ({
  type,
  q,
  month,
  year,
  pageIndex,
  pageSize,
  completed = false,
}) => {
  let searchBaseUrl = `${baseUrl}/transaction?pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (!isNil(q) && !isNil(type)) searchBaseUrl += `&${type}=${encodeURIComponent(q)}`;
  if (!isNil(month)) searchBaseUrl += `&month=${month}`;
  if (!isNil(year)) searchBaseUrl += `&year=${year}`;
  if (completed === "true" || completed === "false") {
    searchBaseUrl += `&completed=${completed}`;
  }

  return fetch(searchBaseUrl, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all payment details failed");
    });
};

export const fetchPaymentDetailById = ({ id }) => {
  return fetch(`${baseUrl}/transaction/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get payment detail by id failed");
    });
};

export const fetchUsers = ({ studentUsername, studentUserId, parentEmail }) => {
  let baseEndpoint = `${baseUrl}/user?`;

  const updateUrl = (type, value) => {
    baseEndpoint = `${baseUrl}/user?${type}=${value}`;
  };

  if (!isNil(studentUsername)) updateUrl("username", studentUsername);
  if (!isNil(studentUserId)) updateUrl("userId", studentUserId);
  if (!isNil(parentEmail)) updateUrl("parentEmail", parentEmail);

  return fetch(baseEndpoint, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get subscription by id failed");
    });
};

export const fetchSubscriptionById = ({ userId }) => {
  return fetch(`${baseUrl}/subscription/${userId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get subscription by id failed");
    });
};

export const postSubscriptionById = (params) => {
  const { id, subscriptionId, userId } = params;

  return fetch(`${baseUrl}/transaction/${id}`, {
    method: "POST",
    body: JSON.stringify({ subscriptionId, userId }),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update subscription by id failed");
    });
};
